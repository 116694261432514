import type { PersonSportRankingResponseRelatedRankingResponseRelatedPagedData } from '@on3/api';
import { useSite } from '@on3/ui-lib/index';
import { formatCurrency } from '@on3/ui-lib/utils/formatters';
import { On3Avatar } from 'components/Image/On3Avatar';
import { SideModuleWrapper } from 'components/UI/Layout/Containers/Sidebar/SideModuleWrapper';
import { TextLink } from 'components/UI/Link/TextLink';
import { StarRating } from 'components/UI/StarRating/StarRating';
import { Text } from 'components/UI/Typography/Text';

import styles from './PlayerRankingsModule.module.scss';

interface IPlayerRankingModuleProps {
  playerData: PersonSportRankingResponseRelatedRankingResponseRelatedPagedData | null;
  selectedSport?: string;
}
export const PlayerRankingsModule = ({
  playerData,
  selectedSport,
}: IPlayerRankingModuleProps) => {
  const { defaultYears } = useSite();
  const { footballRecruitingYear, nonFootballRecruitingYear } = defaultYears;
  const recruitingYear =
    selectedSport === 'football'
      ? footballRecruitingYear
      : nonFootballRecruitingYear;
  const { list } = playerData ?? {};
  const viewAllplayer = `/db/rankings/industry-player/${selectedSport}/${recruitingYear}/`;

  return (
    <SideModuleWrapper className={styles.container} title="Player Rankings">
      <ol className={styles.playerListWrapper}>
        {list?.slice(0, 10).map((item) => {
          const {
            consensusOverallRank,
            positionAbbreviation,
            nilValue,
            person,
            key,
          } = item;
          const { rating, name, slug, defaultAsset, formattedHeight, weight } =
            person ?? {};
          const { fiveStarPlus, stars, consensusRating } = rating ?? {};
          const recruitHref = (slug && `/db/${slug}/`) || '';

          return (
            <li className={styles.playerRankingItem} key={key}>
              <div className={styles.rankContainer}>
                <Text className={styles.consensusOverallRank}>
                  {consensusOverallRank}
                </Text>
              </div>
              <On3Avatar
                altText={`${name} Avatar` || ''}
                className={styles.avatar}
                domain={defaultAsset?.domain}
                source={defaultAsset?.source}
              />
              <div className={styles.nameWrapper}>
                <TextLink className={styles.name} href={`/db/${slug}/`}>
                  {name}
                </TextLink>
                <div className={styles.vitalsWrapper}>
                  <Text className={styles.playerVitals} component="div">
                    <span className={styles.positionAbr}>
                      {positionAbbreviation}
                    </span>{' '}
                    <span role="separator">/</span>
                    <span className={styles.height}>{formattedHeight}</span>
                    <span role="separator">/</span>
                    <span>{weight}</span>
                  </Text>
                  <StarRating
                    className={styles.starRating}
                    fiveStarPlus={fiveStarPlus}
                    grayRating={true}
                    hasBorder={false}
                    href={recruitHref}
                    isBoldRating={false}
                    isConsensus
                    rating={consensusRating}
                    small
                    stars={stars}
                  />
                </div>
              </div>
              {!!nilValue && nilValue && (
                <div className={styles.nilContainer}>
                  <Text className={styles.nilLabel}>NIL</Text>
                  <Text className={styles.nilValuation}>
                    {formatCurrency(nilValue)}
                  </Text>
                </div>
              )}
            </li>
          );
        })}
      </ol>
      <footer className={styles.footer}>
        <TextLink
          className={styles.viewAll}
          href={viewAllplayer}
          underline="none"
          variant="subtitle1"
        >
          Player Rankings
        </TextLink>
      </footer>
    </SideModuleWrapper>
  );
};
